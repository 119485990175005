import React, { lazy as l } from 'react';
import { RouteObject } from 'react-router-dom';
import RoutePath, { IRoutePath } from 'helpers/routePath';
import { SidebarType, ISidebarType } from 'components/features/sidebar/SidebarTypes';
import { vsprintf } from 'sprintf-js';
import RouteMatch from 'helpers/routeMatch';
import { CompanyProductDetailAlias, CompanyDetailAlias } from 'helpers/companies';
import { ContractDetailAlias } from 'helpers/contracts';

const RootPage = l(() => import('pages/root/RootPage'));
const NotFoundPage = l(() => import('pages/notFound/NotFoundPage'));

const AuthPage = l(() => import('pages/auth/AuthPage'));

const LogsPage = l(() => import('pages/logs/LogsPage'));

const SettingsPage = l(() => import('pages/settings/SettingsPage'));
const SettingsUsersGroupsPage = l(() => import('pages/settings/users/groups/SettingsUsersGroupsPage'));
const SettingsUsersGroupsCreatePage = l(() => import('pages/settings/users/groups/create/SettingsUsersGroupsCreatePage'));
const SettingsUsersGroupsEditPage = l(() => import('pages/settings/users/groups/edit/SettingsUsersGroupsEditPage'));
const SettingsUsersUsersPage = l(() => import('pages/settings/users/users/SettingsUsersUsersPage'));
const SettingsUsersUsersCreatePage = l(() => import('pages/settings/users/users/create/SettingsUsersUsersCreatePage'));
const SettingsUsersUsersEditPage = l(() => import('pages/settings/users/users/edit/SettingsUsersUsersEditPage'));
const SettingsUsersUsersPasswordEditPage = l(() => import('pages/settings/users/users/password/edit/SettingsUsersUsersPasswordEditPage'));
const SettingsBillingContributionsPage = l(() => import('pages/settings/billing/contributions/SettingsBillingContributionsPage'));
const SettingsBillingContributionsCreatePage = l(
  () => import('pages/settings/billing/contributions/create/SettingsBillingContributionsCreatePage'),
);
const SettingsBillingContributionsEditPage = l(
  () => import('pages/settings/billing/contributions/edit/SettingsBillingContributionsEditPage'),
);
const SettingsBillingCompanyCategoriesPage = l(
  () => import('pages/settings/billing/companyCategories/SettingsBillingCompanyCategoriesPage'),
);
const SettingsBillingCompanyCategoriesCreatePage = l(
  () => import('pages/settings/billing/companyCategories/create/SettingsBillingCompanyCategoriesCreatePage'),
);
const SettingsBillingCompanyCategoriesEditPage = l(
  () => import('pages/settings/billing/companyCategories/edit/SettingsBillingCompanyCategoriesEditPage'),
);
const SettingsGeoCountriesPage = l(() => import('pages/settings/geo/countries/SettingsGeoCountriesPage'));
const SettingsGeoCountriesCreatePage = l(() => import('pages/settings/geo/countries/create/SettingsGeoCountriesCreatePage'));
const SettingsGeoCountriesEditPage = l(() => import('pages/settings/geo/countries/edit/SettingsGeoCountriesEditPage'));
const SettingsGeoRegionsPage = l(() => import('pages/settings/geo/regions/SettingsGeoRegionsPage'));
const SettingsGeoRegionsCreatePage = l(() => import('pages/settings/geo/regions/create/SettingsGeoRegionsCreatePage'));
const SettingsGeoRegionsEditPage = l(() => import('pages/settings/geo/regions/edit/SettingsGeoRegionsEditPage'));
const SettingsGeoCitiesPage = l(() => import('pages/settings/geo/cities/SettingsGeoCitiesPage'));
const SettingsGeoCitiesCreatePage = l(() => import('pages/settings/geo/cities/create/SettingsGeoCitiesCreatePage'));
const SettingsGeoCitiesEditPage = l(() => import('pages/settings/geo/cities/edit/SettingsGeoCitiesEditPage'));
const SettingsGeoDistrictsPage = l(() => import('pages/settings/geo/districts/SettingsGeoDistrictsPage'));
const SettingsGeoDistrictsCreatePage = l(() => import('pages/settings/geo/districts/create/SettingsGeoDistrictsCreatePage'));
const SettingsGeoDistrictsEditPage = l(() => import('pages/settings/geo/districts/edit/SettingsGeoDistrictsEditPage'));
const SettingsGeoBranchesPage = l(() => import('pages/settings/geo/branches/SettingsGeoBranchesPage'));
const SettingsGeoBranchesCreatePage = l(() => import('pages/settings/geo/branches/create/SettingsGeoBranchesCreatePage'));
const SettingsGeoBranchesEditPage = l(() => import('pages/settings/geo/branches/edit/SettingsGeoBranchesEditPage'));
const SettingsSystemAgreementPage = l(() => import('pages/settings/system/agreement/SettingsSystemAgreementPage'));
const SettingsSystemAgreementEditPage = l(() => import('pages/settings/system/agreement/edit/SettingsSystemAgreementEditPage'));
const SettingsSystemContractsPage = l(() => import('pages/settings/system/contracts/SettingsSystemContractsPage'));
const SettingsSystemContractsCreatePage = l(() => import('pages/settings/system/contracts/create/SettingsSystemContractsCreatePage'));
const SettingsSystemContractsEditPage = l(() => import('pages/settings/system/contracts/edit/SettingsSystemContractsEditPage'));
const SettingsSystemOwnershipsPage = l(() => import('pages/settings/system/ownerships/SettingsSystemOwnershipsPage'));
const SettingsSystemOwnershipsCreatePage = l(() => import('pages/settings/system/ownerships/create/SettingsSystemOwnershipsCreatePage'));
const SettingsSystemOwnershipsEditPage = l(() => import('pages/settings/system/ownerships/edit/SettingsSystemOwnershipsEditPage'));
const SettingsSystemBusinessActivitiesPage = l(
  () => import('pages/settings/system/businessActivities/SettingsSystemBusinessActivitiesPage'),
);
const SettingsSystemBusinessActivitiesCreatePage = l(
  () => import('pages/settings/system/businessActivities/create/SettingsSystemBusinessActivitiesCreatePage'),
);
const SettingsSystemBusinessActivitiesEditPage = l(
  () => import('pages/settings/system/businessActivities/edit/SettingsSystemBusinessActivitiesEditPage'),
);
const SettingsSystemEconomicActivitiesPage = l(
  () => import('pages/settings/system/economicActivities/SettingsSystemEconomicActivitiesPage'),
);
const SettingsSystemEconomicActivitiesCreatePage = l(
  () => import('pages/settings/system/economicActivities/create/SettingsSystemEconomicActivitiesCreatePage'),
);
const SettingsSystemEconomicActivitiesEditPage = l(
  () => import('pages/settings/system/economicActivities/edit/SettingsSystemEconomicActivitiesEditPage'),
);
const SettingsSystemProductTypesPage = l(() => import('pages/settings/system/productTypes/SettingsSystemProductTypesPage'));
const SettingsSystemBanksPage = l(() => import('pages/settings/system/banks/SettingsSystemBanksPage'));
const SettingsSystemBanksCreatePage = l(() => import('pages/settings/system/banks/create/SettingsSystemBanksCreatePage'));
const SettingsSystemBanksEditPage = l(() => import('pages/settings/system/banks/edit/SettingsSystemBanksEditPage'));

const CompaniesPage = l(() => import('pages/companies/CompaniesPage'));
const CompaniesCreatePage = l(() => import('pages/companies/create/CompaniesCreatePage'));
const CompaniesEditPage = l(() => import('pages/companies/edit/CompaniesEditPage'));
const CompaniesDetailPage = l(() => import('pages/companies/detail/CompaniesDetailPage'));
const CompaniesInfoPage = l(() => import('pages/companies/detail/info/CompaniesInfoPage'));
const CompaniesInfoMergePage = l(() => import('pages/companies/detail/info/merge/CompaniesInfoMergePage'));
const CompaniesRequisitesPage = l(() => import('pages/companies/detail/requisites/CompaniesRequisitesPage'));
const CompaniesGlnsPage = l(() => import('pages/companies/detail/glns/CompaniesGlnsPage'));
const CompaniesProductsPage = l(() => import('pages/companies/detail/products/CompaniesProductsPage'));
const CompaniesProductsCreatePage = l(() => import('pages/companies/detail/products/create/CompaniesProductsCreatePage'));
const CompaniesProductsEditPage = l(() => import('pages/companies/detail/products/edit/CompaniesProductsEditPage'));
const CompaniesProductsDetailPage = l(() => import('pages/companies/detail/products/detail/CompaniesProductsDetailPage'));
const CompaniesProductsInfoPage = l(() => import('pages/companies/detail/products/detail/info/CompaniesProductsInfoPage'));
const CompaniesProductsSyncPage = l(() => import('pages/companies/detail/products/detail/sync/CompaniesProductsSyncPage'));
const CompaniesContractsPage = l(() => import('pages/companies/detail/contracts/CompaniesContractsPage'));
const CompaniesContractsDetailPage = l(() => import('pages/companies/detail/contracts/detail/CompaniesContractsDetailPage'));
const CompaniesContractsActivatePage = l(() => import('pages/companies/detail/contracts/activate/CompaniesContractsActivatePage'));
const CompaniesContractsPayPage = l(() => import('pages/companies/detail/contracts/pay/CompaniesContractsInvoicesPayPage'));
const CompaniesContractsInvoicesCreatePage = l(
  () => import('pages/companies/detail/contracts/detail/invoices/create/CompaniesContractsInvoicesCreatePage'),
);
const CompaniesContractsInvoicesEditPage = l(
  () => import('pages/companies/detail/contracts/detail/invoices/edit/CompaniesContractsInvoicesEditPage'),
);
const CompaniesContractsInvoicesActivatePage = l(
  () => import('pages/companies/detail/contracts/detail/invoices/activate/CompaniesContractsInvoicesActivatePage'),
);
const CompaniesContractsInvoicesPayPage = l(
  () => import('pages/companies/detail/contracts/detail/invoices/pay/CompaniesContractsInvoicesPayPage'),
);
const CompaniesContractsCreatePage = l(() => import('pages/companies/detail/contracts/create/CompaniesContractsCreatePage'));
const CompaniesContractsEditPage = l(() => import('pages/companies/detail/contracts/edit/CompaniesContractsEditPage'));
const CompaniesContractsHistoryPage = l(() => import('pages/companies/detail/contracts/history/CompaniesContractsHistoryPage'));
const CompaniesContractsCommentCreatePage = l(
  () => import('pages/companies/detail/contracts/comment/create/CompaniesContractsCommentCreatePage'),
);
const CompaniesHistoryPage = l(() => import('pages/companies/detail/history/CompaniesHistoryPage'));
const CompaniesHistoryCreatePage = l(() => import('pages/companies/detail/history/create/CompaniesHistoryCreatePage'));
const CompaniesDocsPage = l(() => import('pages/companies/detail/docs/CompaniesDocsPage'));
const CompaniesSyncPage = l(() => import('pages/companies/detail/sync/CompaniesSyncPage'));

const ContractsPage = l(() => import('pages/contracts/ContractsPage'));
const ContractsCreatePage = l(() => import('pages/contracts/create/ContractsCreatePage'));
const ContractsEditPage = l(() => import('pages/contracts/edit/ContractsEditPage'));
const ContractsActivatePage = l(() => import('pages/contracts/activate/ContractsActivatePage'));
const ContractsPayPage = l(() => import('pages/contracts/pay/ContractsPayPage'));
const ContractsDetailPage = l(() => import('pages/contracts/detail/ContractsDetailPage'));
const ContractsInfoPage = l(() => import('pages/contracts/detail/info/ContractsInfoPage'));
const ContractsCommentCreatePage = l(() => import('pages/contracts/detail/info/comment/create/ContractsCommentCreatePage'));
const ContractsInvoicesPage = l(() => import('pages/contracts/detail/invoices/ContractsInvoicesPage'));
const ContractsInvoicesActivatePage = l(() => import('pages/contracts/detail/invoices/activate/ContractsInvoicesActivatePage'));
const ContractsInvoicesPayPage = l(() => import('pages/contracts/detail/invoices/pay/ContractsInvoicesPayPage'));
const ContractsInvoicesCreatePage = l(() => import('pages/contracts/detail/invoices/create/ContractsInvoicesCreatePage'));
const ContractsInvoicesEditPage = l(() => import('pages/contracts/detail/invoices/edit/ContractsInvoicesEditPage'));

const ProductsPage = l(() => import('pages/products/ProductsPage'));
const ProductsCreatePage = l(() => import('pages/products/create/ProductsCreatePage'));
const ProductsEditPage = l(() => import('pages/products/edit/ProductsEditPage'));

const StatisticsPage = l(() => import('pages/statistics/StatisticsPage'));

// TODO Rou 17.06.2023 Fix any type?!
type IRoute = RouteObject & any & {
  path: IRoutePath | string
  sidebar?: ISidebarType
  children?: IRoute[]
};
type IRoutes = IRoute[];

const routes: IRoutes = [
  // Root
  {
    path: RoutePath.ROOT,
    element: <RootPage />,
    sidebar: SidebarType.FULL,
  },
  // Not found
  {
    path: RoutePath.ETC,
    element: <NotFoundPage />,
    sidebar: SidebarType.COMPACT,
  },
  // Auth
  {
    path: RoutePath.AUTH,
    element: <AuthPage />,
    sidebar: SidebarType.HIDE,
  },
  // Logs
  {
    path: RoutePath.LOGS,
    element: <LogsPage />,
    sidebar: SidebarType.FULL,
  },
  // Settings
  {
    path: RoutePath.SETTINGS,
    element: <SettingsPage />,
    sidebar: SidebarType.FULL,
  },
  {
    path: vsprintf(RoutePath.SETTINGS_BY_ALIAS, [ `:${RouteMatch.SETTINGS_ALIAS}` ]),
    element: <SettingsPage />,
    sidebar: SidebarType.FULL,
  },
  {
    path: RoutePath.SETTINGS_USERS_GROUPS,
    element: <SettingsUsersGroupsPage />,
    sidebar: SidebarType.FULL,
  },
  {
    path: RoutePath.SETTINGS_USERS_GROUPS_CREATE,
    element: <SettingsUsersGroupsCreatePage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: vsprintf(RoutePath.SETTINGS_USERS_GROUPS_EDIT, [ `:${RouteMatch.USERS_GROUPS_ID}` ]),
    element: <SettingsUsersGroupsEditPage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: RoutePath.SETTINGS_USERS_USERS,
    element: <SettingsUsersUsersPage />,
    sidebar: SidebarType.FULL,
    children: [
      {
        path: vsprintf(RoutePath.SETTINGS_USERS_USERS_PASSWORD_EDIT, [ `:${RouteMatch.USERS_USERS_ID}` ]),
        element: <SettingsUsersUsersPasswordEditPage />,
        sidebar: SidebarType.FULL,
      },
    ],
  },
  {
    path: RoutePath.SETTINGS_USERS_USERS_CREATE,
    element: <SettingsUsersUsersCreatePage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: vsprintf(RoutePath.SETTINGS_USERS_USERS_EDIT, [ `:${RouteMatch.USERS_USERS_ID}` ]),
    element: <SettingsUsersUsersEditPage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: RoutePath.SETTINGS_BILLING_CONTRIBUTIONS,
    element: <SettingsBillingContributionsPage />,
    sidebar: SidebarType.FULL,
  },
  {
    path: RoutePath.SETTINGS_BILLING_CONTRIBUTIONS_CREATE,
    element: <SettingsBillingContributionsCreatePage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: vsprintf(RoutePath.SETTINGS_BILLING_CONTRIBUTIONS_EDIT, [ `:${RouteMatch.BILLING_CONTRIBUTIONS_ID}` ]),
    element: <SettingsBillingContributionsEditPage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: RoutePath.SETTINGS_BILLING_COMPANY_CATEGORIES,
    element: <SettingsBillingCompanyCategoriesPage />,
    sidebar: SidebarType.FULL,
  },
  {
    path: RoutePath.SETTINGS_BILLING_COMPANY_CATEGORIES_CREATE,
    element: <SettingsBillingCompanyCategoriesCreatePage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: vsprintf(RoutePath.SETTINGS_BILLING_COMPANY_CATEGORIES_EDIT, [ `:${RouteMatch.BILLING_COMPANY_CATEGORIES_ID}` ]),
    element: <SettingsBillingCompanyCategoriesEditPage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: RoutePath.SETTINGS_GEO_COUNTRIES,
    element: <SettingsGeoCountriesPage />,
    sidebar: SidebarType.FULL,
  },
  {
    path: RoutePath.SETTINGS_GEO_COUNTRIES_CREATE,
    element: <SettingsGeoCountriesCreatePage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: vsprintf(RoutePath.SETTINGS_GEO_COUNTRIES_EDIT, [ `:${RouteMatch.GEO_COUNTRIES_ID}` ]),
    element: <SettingsGeoCountriesEditPage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: RoutePath.SETTINGS_GEO_REGIONS,
    element: <SettingsGeoRegionsPage />,
    sidebar: SidebarType.FULL,
  },
  {
    path: RoutePath.SETTINGS_GEO_REGIONS_CREATE,
    element: <SettingsGeoRegionsCreatePage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: vsprintf(RoutePath.SETTINGS_GEO_REGIONS_EDIT, [ `:${RouteMatch.GEO_REGIONS_ID}` ]),
    element: <SettingsGeoRegionsEditPage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: RoutePath.SETTINGS_GEO_CITIES,
    element: <SettingsGeoCitiesPage />,
    sidebar: SidebarType.FULL,
  },
  {
    path: RoutePath.SETTINGS_GEO_CITIES_CREATE,
    element: <SettingsGeoCitiesCreatePage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: vsprintf(RoutePath.SETTINGS_GEO_CITIES_EDIT, [ `:${RouteMatch.GEO_CITIES_ID}` ]),
    element: <SettingsGeoCitiesEditPage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: RoutePath.SETTINGS_GEO_DISTRICTS,
    element: <SettingsGeoDistrictsPage />,
    sidebar: SidebarType.FULL,
  },
  {
    path: RoutePath.SETTINGS_GEO_DISTRICTS_CREATE,
    element: <SettingsGeoDistrictsCreatePage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: vsprintf(RoutePath.SETTINGS_GEO_DISTRICTS_EDIT, [ `:${RouteMatch.GEO_DISTRICTS_ID}` ]),
    element: <SettingsGeoDistrictsEditPage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: RoutePath.SETTINGS_GEO_BRANCHES,
    element: <SettingsGeoBranchesPage />,
    sidebar: SidebarType.FULL,
  },
  {
    path: RoutePath.SETTINGS_GEO_BRANCHES_CREATE,
    element: <SettingsGeoBranchesCreatePage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: vsprintf(RoutePath.SETTINGS_GEO_BRANCHES_EDIT, [ `:${RouteMatch.GEO_BRANCHES_ID}` ]),
    element: <SettingsGeoBranchesEditPage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: RoutePath.SETTINGS_SYSTEM_AGREEMENT,
    element: <SettingsSystemAgreementPage />,
    sidebar: SidebarType.FULL,
  },
  {
    path: RoutePath.SETTINGS_SYSTEM_AGREEMENT_EDIT,
    element: <SettingsSystemAgreementEditPage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: RoutePath.SETTINGS_SYSTEM_CONTRACTS,
    element: <SettingsSystemContractsPage />,
    sidebar: SidebarType.FULL,
  },
  {
    path: RoutePath.SETTINGS_SYSTEM_CONTRACTS_CREATE,
    element: <SettingsSystemContractsCreatePage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: vsprintf(RoutePath.SETTINGS_SYSTEM_CONTRACTS_EDIT, [ `:${RouteMatch.SYSTEM_CONTRACTS_ID}` ]),
    element: <SettingsSystemContractsEditPage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: RoutePath.SETTINGS_SYSTEM_OWNERSHIPS,
    element: <SettingsSystemOwnershipsPage />,
    sidebar: SidebarType.FULL,
  },
  {
    path: RoutePath.SETTINGS_SYSTEM_OWNERSHIPS_CREATE,
    element: <SettingsSystemOwnershipsCreatePage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: vsprintf(RoutePath.SETTINGS_SYSTEM_OWNERSHIPS_EDIT, [ `:${RouteMatch.SYSTEM_OWNERSHIPS_ID}` ]),
    element: <SettingsSystemOwnershipsEditPage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: RoutePath.SETTINGS_SYSTEM_BUSINESS_ACTIVITIES,
    element: <SettingsSystemBusinessActivitiesPage />,
    sidebar: SidebarType.FULL,
  },
  {
    path: RoutePath.SETTINGS_SYSTEM_BUSINESS_ACTIVITIES_CREATE,
    element: <SettingsSystemBusinessActivitiesCreatePage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: vsprintf(RoutePath.SETTINGS_SYSTEM_BUSINESS_ACTIVITIES_EDIT, [ `:${RouteMatch.SYSTEM_BUSINESS_ACTIVITIES_ID}` ]),
    element: <SettingsSystemBusinessActivitiesEditPage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: RoutePath.SETTINGS_SYSTEM_ECONOMIC_ACTIVITIES,
    element: <SettingsSystemEconomicActivitiesPage />,
    sidebar: SidebarType.FULL,
  },
  {
    path: RoutePath.SETTINGS_SYSTEM_ECONOMIC_ACTIVITIES_CREATE,
    element: <SettingsSystemEconomicActivitiesCreatePage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: vsprintf(RoutePath.SETTINGS_SYSTEM_ECONOMIC_ACTIVITIES_EDIT, [ `:${RouteMatch.SYSTEM_ECONOMIC_ACTIVITIES_ID}` ]),
    element: <SettingsSystemEconomicActivitiesEditPage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: RoutePath.SETTINGS_SYSTEM_PRODUCT_TYPES,
    element: <SettingsSystemProductTypesPage />,
    sidebar: SidebarType.FULL,
  },
  {
    path: RoutePath.SETTINGS_SYSTEM_BANKS,
    element: <SettingsSystemBanksPage />,
    sidebar: SidebarType.FULL,
  },
  {
    path: RoutePath.SETTINGS_SYSTEM_BANKS_CREATE,
    element: <SettingsSystemBanksCreatePage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: vsprintf(RoutePath.SETTINGS_SYSTEM_BANKS_EDIT, [ `:${RouteMatch.SYSTEM_BANKS_ID}` ]),
    element: <SettingsSystemBanksEditPage />,
    sidebar: SidebarType.HIDE,
  },
  // Companies
  {
    path: RoutePath.COMPANIES,
    element: <CompaniesPage />,
    sidebar: SidebarType.FULL,
  },
  {
    path: vsprintf(RoutePath.COMPANIES_BY_STATUS, [ `:${RouteMatch.COMPANY_STATUS}` ]),
    element: <CompaniesPage />,
    sidebar: SidebarType.FULL,
  },
  {
    path: RoutePath.COMPANIES_CREATE,
    element: <CompaniesCreatePage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: vsprintf(RoutePath.COMPANIES_EDIT, [ `:${RouteMatch.COMPANY_ID}` ]),
    element: <CompaniesEditPage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: vsprintf(RoutePath.COMPANIES_DETAIL, [ `:${RouteMatch.COMPANY_ID}` ]),
    element: <CompaniesDetailPage />,
    sidebar: SidebarType.COMPACT,
    children: [
      {
        path: vsprintf(RoutePath.COMPANIES_DETAIL_BY_ALIAS, [ `:${RouteMatch.COMPANY_ID}`, CompanyDetailAlias.INFO ]),
        element: <CompaniesInfoPage />,
        sidebar: SidebarType.COMPACT,
        children: [
          {
            path: vsprintf(RoutePath.COMPANIES_INFO_MERGE, [ `:${RouteMatch.COMPANY_ID}` ]),
            element: <CompaniesInfoMergePage />,
            sidebar: SidebarType.COMPACT,
          },
        ],
      },
      {
        path: vsprintf(RoutePath.COMPANIES_DETAIL_BY_ALIAS, [ `:${RouteMatch.COMPANY_ID}`, CompanyDetailAlias.REQUISITES ]),
        element: <CompaniesRequisitesPage />,
        sidebar: SidebarType.COMPACT,
      },
      {
        path: vsprintf(RoutePath.COMPANIES_DETAIL_BY_ALIAS, [ `:${RouteMatch.COMPANY_ID}`, CompanyDetailAlias.GLNS ]),
        element: <CompaniesGlnsPage />,
        sidebar: SidebarType.COMPACT,
      },
      {
        path: vsprintf(RoutePath.COMPANIES_DETAIL_BY_ALIAS, [ `:${RouteMatch.COMPANY_ID}`, CompanyDetailAlias.PRODUCTS ]),
        element: <CompaniesProductsPage />,
        sidebar: SidebarType.COMPACT,
        children: [
          {
            path: vsprintf(RoutePath.COMPANIES_PRODUCTS_DETAIL, [ `:${RouteMatch.COMPANY_ID}`, `:${RouteMatch.PRODUCT_ID}` ]),
            element: <CompaniesProductsDetailPage />,
            sidebar: SidebarType.COMPACT,
            children: [
              {
                path: vsprintf(
                  RoutePath.COMPANIES_PRODUCTS_DETAIL_BY_ALIAS,
                  [ `:${RouteMatch.COMPANY_ID}`, `:${RouteMatch.PRODUCT_ID}`, CompanyProductDetailAlias.INFO ],
                ),
                element: <CompaniesProductsInfoPage />,
                sidebar: SidebarType.COMPACT,
              },
              {
                path: vsprintf(
                  RoutePath.COMPANIES_PRODUCTS_DETAIL_BY_ALIAS,
                  [ `:${RouteMatch.COMPANY_ID}`, `:${RouteMatch.PRODUCT_ID}`, CompanyProductDetailAlias.SYNC ],
                ),
                element: <CompaniesProductsSyncPage />,
                sidebar: SidebarType.COMPACT,
              },
            ],
          },
        ],
      },
      {
        path: vsprintf(RoutePath.COMPANIES_DETAIL_BY_ALIAS, [ `:${RouteMatch.COMPANY_ID}`, CompanyDetailAlias.CONTRACTS ]),
        element: <CompaniesContractsPage />,
        sidebar: SidebarType.COMPACT,
        children: [
          {
            path: vsprintf(RoutePath.COMPANIES_CONTRACTS_DETAIL, [ `:${RouteMatch.COMPANY_ID}`, `:${RouteMatch.CONTRACT_ID}` ]),
            element: <CompaniesContractsDetailPage />,
            sidebar: SidebarType.COMPACT,
            children: [
              {
                path: vsprintf(
                  RoutePath.COMPANIES_CONTRACTS_INVOICE_CREATE,
                  [ `:${RouteMatch.COMPANY_ID}`, `:${RouteMatch.CONTRACT_ID}` ],
                ),
                element: <CompaniesContractsInvoicesCreatePage />,
                sidebar: SidebarType.COMPACT,
              },
              {
                path: vsprintf(
                  RoutePath.COMPANIES_CONTRACTS_INVOICE_EDIT,
                  [ `:${RouteMatch.COMPANY_ID}`, `:${RouteMatch.CONTRACT_ID}`, `:${RouteMatch.INVOICE_ID}` ],
                ),
                element: <CompaniesContractsInvoicesEditPage />,
                sidebar: SidebarType.COMPACT,
              },
              {
                path: vsprintf(
                  RoutePath.COMPANIES_CONTRACTS_INVOICE_ACTIVATE,
                  [ `:${RouteMatch.COMPANY_ID}`, `:${RouteMatch.CONTRACT_ID}`, `:${RouteMatch.INVOICE_ID}` ],
                ),
                element: <CompaniesContractsInvoicesActivatePage />,
                sidebar: SidebarType.COMPACT,
              },
              {
                path: vsprintf(
                  RoutePath.COMPANIES_CONTRACTS_INVOICE_PAY,
                  [ `:${RouteMatch.COMPANY_ID}`, `:${RouteMatch.CONTRACT_ID}`, `:${RouteMatch.INVOICE_ID}` ],
                ),
                element: <CompaniesContractsInvoicesPayPage />,
                sidebar: SidebarType.COMPACT,
              },
            ],
          },
          {
            path: vsprintf(RoutePath.COMPANIES_CONTRACTS_REGISTER, [ `:${RouteMatch.COMPANY_ID}` ]),
            element: <CompaniesContractsCreatePage />,
            sidebar: SidebarType.COMPACT,
          },
          {
            path: vsprintf(RoutePath.COMPANIES_CONTRACTS_ACTIVATE, [ `:${RouteMatch.COMPANY_ID}`, `:${RouteMatch.CONTRACT_ID}` ]),
            element: <CompaniesContractsActivatePage />,
            sidebar: SidebarType.COMPACT,
          },
          {
            path: vsprintf(RoutePath.COMPANIES_CONTRACTS_PAY, [ `:${RouteMatch.COMPANY_ID}`, `:${RouteMatch.CONTRACT_ID}` ]),
            element: <CompaniesContractsPayPage />,
            sidebar: SidebarType.COMPACT,
          },
          {
            path: vsprintf(RoutePath.COMPANIES_CONTRACTS_EDIT, [ `:${RouteMatch.COMPANY_ID}`, `:${RouteMatch.CONTRACT_ID}` ]),
            element: <CompaniesContractsEditPage />,
            sidebar: SidebarType.COMPACT,
          },
          {
            path: vsprintf(RoutePath.COMPANIES_CONTRACTS_HISTORY, [ `:${RouteMatch.COMPANY_ID}` ]),
            element: <CompaniesContractsHistoryPage />,
            sidebar: SidebarType.COMPACT,
          },
          {
            path: vsprintf(RoutePath.COMPANIES_CONTRACTS_COMMENT_CREATE, [ `:${RouteMatch.COMPANY_ID}`, `:${RouteMatch.CONTRACT_ID}` ]),
            element: <CompaniesContractsCommentCreatePage />,
            sidebar: SidebarType.COMPACT,
          },
        ],
      },
      {
        path: vsprintf(RoutePath.COMPANIES_DETAIL_BY_ALIAS, [ `:${RouteMatch.COMPANY_ID}`, CompanyDetailAlias.HISTORY ]),
        element: <CompaniesHistoryPage />,
        sidebar: SidebarType.COMPACT,
        children: [
          {
            path: vsprintf(RoutePath.COMPANIES_HISTORY_CREATE, [ `:${RouteMatch.COMPANY_ID}` ]),
            element: <CompaniesHistoryCreatePage />,
            sidebar: SidebarType.COMPACT,
          },
        ],
      },
      {
        path: vsprintf(RoutePath.COMPANIES_DETAIL_BY_ALIAS, [ `:${RouteMatch.COMPANY_ID}`, CompanyDetailAlias.DOCS ]),
        element: <CompaniesDocsPage />,
        sidebar: SidebarType.COMPACT,
      },
      {
        path: vsprintf(RoutePath.COMPANIES_DETAIL_BY_ALIAS, [ `:${RouteMatch.COMPANY_ID}`, CompanyDetailAlias.SYNC ]),
        element: <CompaniesSyncPage />,
        sidebar: SidebarType.COMPACT,
      },
    ],
  },
  {
    path: vsprintf(RoutePath.COMPANIES_PRODUCTS_CREATE, [ `:${RouteMatch.COMPANY_ID}` ]),
    element: <CompaniesProductsCreatePage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: vsprintf(RoutePath.COMPANIES_PRODUCTS_EDIT, [ `:${RouteMatch.COMPANY_ID}`, `:${RouteMatch.PRODUCT_ID}` ]),
    element: <CompaniesProductsEditPage />,
    sidebar: SidebarType.HIDE,
  },
  // Contracts
  {
    path: RoutePath.CONTRACTS,
    element: <ContractsPage />,
    sidebar: SidebarType.FULL,
  },
  {
    path: vsprintf(RoutePath.CONTRACTS_BY_STATUS, [ `:${RouteMatch.CONTRACT_STATUS}` ]),
    element: <ContractsPage />,
    sidebar: SidebarType.FULL,
  },
  {
    path: RoutePath.CONTRACTS_CREATE,
    element: <ContractsCreatePage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: vsprintf(RoutePath.CONTRACTS_EDIT, [ `:${RouteMatch.CONTRACT_ID}` ]),
    element: <ContractsEditPage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: vsprintf(RoutePath.CONTRACTS_DETAIL, [ `:${RouteMatch.CONTRACT_ID}` ]),
    element: <ContractsDetailPage />,
    sidebar: SidebarType.COMPACT,
    children: [
      {
        path: vsprintf(RoutePath.CONTRACTS_DETAIL_BY_ALIAS, [ `:${RouteMatch.CONTRACT_ID}`, ContractDetailAlias.INFO ]),
        element: <ContractsInfoPage />,
        sidebar: SidebarType.COMPACT,
        children: [
          {
            path: vsprintf(RoutePath.CONTRACTS_ACTIVATE, [ `:${RouteMatch.CONTRACT_ID}` ]),
            element: <ContractsActivatePage />,
            sidebar: SidebarType.COMPACT,
          },
          {
            path: vsprintf(RoutePath.CONTRACTS_PAY, [ `:${RouteMatch.CONTRACT_ID}` ]),
            element: <ContractsPayPage />,
            sidebar: SidebarType.COMPACT,
          },
          {
            path: vsprintf(RoutePath.CONTRACTS_COMMENT_CREATE, [ `:${RouteMatch.CONTRACT_ID}` ]),
            element: <ContractsCommentCreatePage />,
            sidebar: SidebarType.COMPACT,
          },
        ],
      },
      {
        path: vsprintf(RoutePath.CONTRACTS_DETAIL_BY_ALIAS, [ `:${RouteMatch.CONTRACT_ID}`, ContractDetailAlias.INVOICES ]),
        element: <ContractsInvoicesPage />,
        sidebar: SidebarType.COMPACT,
        children: [
          {
            path: vsprintf(RoutePath.CONTRACTS_INVOICE_ACTIVATE, [ `:${RouteMatch.CONTRACT_ID}`, `:${RouteMatch.INVOICE_ID}` ]),
            element: <ContractsInvoicesActivatePage />,
            sidebar: SidebarType.COMPACT,
          },
          {
            path: vsprintf(RoutePath.CONTRACTS_INVOICE_PAY, [ `:${RouteMatch.CONTRACT_ID}`, `:${RouteMatch.INVOICE_ID}` ]),
            element: <ContractsInvoicesPayPage />,
            sidebar: SidebarType.COMPACT,
          },
          {
            path: vsprintf(RoutePath.CONTRACTS_INVOICE_CREATE, [ `:${RouteMatch.CONTRACT_ID}` ]),
            element: <ContractsInvoicesCreatePage />,
            sidebar: SidebarType.COMPACT,
          },
          {
            path: vsprintf(RoutePath.CONTRACTS_INVOICE_EDIT, [ `:${RouteMatch.CONTRACT_ID}`, `:${RouteMatch.INVOICE_ID}` ]),
            element: <ContractsInvoicesEditPage />,
            sidebar: SidebarType.COMPACT,
          },
        ],
      },
    ],
  },
  // Products
  {
    path: RoutePath.PRODUCTS,
    element: <ProductsPage />,
    sidebar: SidebarType.FULL,
  },
  {
    path: vsprintf(RoutePath.PRODUCTS_BY_STATUS, [ `:${RouteMatch.PRODUCT_STATUS}` ]),
    element: <ProductsPage />,
    sidebar: SidebarType.FULL,
  },
  {
    path: RoutePath.PRODUCTS_CREATE,
    element: <ProductsCreatePage />,
    sidebar: SidebarType.HIDE,
  },
  {
    path: vsprintf(RoutePath.PRODUCTS_EDIT, [ `:${RouteMatch.PRODUCT_ID}` ]),
    element: <ProductsEditPage />,
    sidebar: SidebarType.HIDE,
  },
  // Statistics
  {
    path: RoutePath.STATISTICS,
    element: <StatisticsPage />,
    sidebar: SidebarType.COMPACT,
  },
];

export type {
  IRoute,
};
export default routes;
