const RequestAPI = {
  API_URL: process.env.NODE_ENV !== 'production' ? '/stage' : 'https://api.478.uz',
  API_VERSION: '/api/v1',
  AUTH: '/auth/',
  AUTH_CONFIRM: '/auth/verify/',
  AUTH_LOGOUT: '/auth/logout/',
  PERMISSIONS: '/permissions/',
  PROFILE: '/me/',
  UPLOAD: '/upload/',
  COMPANIES: '/companies/',
  BUSINESS_TYPE: '/business_type/',
  COMPANIES_DETAIL: '/companies/%d/',
  COMPANIES_DOCS: '/companies/%d/pdf-signatures/',
  COMPANIES_DOCS_REMOVE_ALL: '/companies/%d/clear_signatures/',
  COMPANIES_HISTORY: '/companies/%d/history/',
  COMPANIES_HISTORY_CREATE: '/companies/%d/write-history/',
  COMPANIES_STATUSES_COUNT: '/companies/statuses_count/',
  COMPANIES_ACTIVATE: '/companies/%d/activate/',
  COMPANIES_EXCLUDE: '/companies/%d/deactivate/',
  COMPANIES_MERGE: '/companies/%d/merge/',
  COMPANIES_RESTORE: '/companies/%d/restore/',
  COMPANIES_CLAIM: '/companies/%d/claim',
  COMPANIES_PRODUCTS: '/products/company/%d/pdf',
  PRODUCTS: '/products/',
  PRODUCTS_DETAIL: '/products/%d/',
  PRODUCTS_COMPANIES: '/companies/products/',
  PRODUCT_TYPES: '/products/product-types/',
  PRODUCTS_STATUSES: '/products/status/',
  PRODUCTS_EXCEL: '/products/excel_uploading',
  PRODUCTS_PACKAGES_EXCEL: '/products/excel_uploading_packages',
  CONTRACTS: '/contracts/',
  CONTRACTS_DETAIL: '/contracts/%d/',
  CONTRACTS_ACTIVATE: '/contracts/%d/activate/',
  CONTRACTS_PAY: '/contracts/%d/pay_amount/',
  CONTRACTS_RESTORE: '/contracts/%d/restore/',
  CONTRACTS_PDF: '/contracts/%d/pdf/',
  CONTRACTS_COMMENT: '/contracts/%d/comment/',
  CONTRACT_INVOICES: '/invoices/',
  CONTRACT_INVOICE_DETAIL: '/invoices/%d/',
  CONTRACT_INVOICE_RESTORE: '/invoices/%d/restore/',
  CONTRACT_INVOICES_EDIT: '/invoices/%d/',
  CONTRACT_INVOICES_ACTIVATE: '/invoices/%d/activate/',
  CONTRACT_INVOICES_PAY: '/invoices/%d/pay_amount/',
  CONTRACT_INVOICES_PAY_RETURN: '/invoices/%d/un_pay/',
  CONTRACT_INVOICES_BY_COMPANY: '/contract/company/%d/invoice/',
  CONTRACT_INVOICES_SEND: '/invoices/%d/send/',
  CONTRACT_INVOICES_PDF: '/invoices/%d/pdf/',
  CONTRACT_INVOICES_CERTIFICATE_PDF: '/invoices/%d/certificate_pdf/',
  COUNTRIES: '/geo/country/',
  COUNTRIES_DETAIL: '/geo/country/%d/',
  HISTORY_DETAIL: '/history/%d/',
  REGIONS: '/geo/regions/',
  REGIONS_DETAIL: '/geo/regions/%d/',
  CITIES: '/geo/city/',
  CITIES_DETAIL: '/geo/city/%d/',
  DISTRICTS: '/districts/',
  DISTRICTS_DETAIL: '/districts/%d/',
  BRANCHES: '/branches/',
  BRANCHES_DETAIL: '/branches/%d/',
  SYSTEM_CONTRACTS: '/contract_pdf/',
  SYSTEM_CONTRACTS_DETAIL: '/contract_pdf/%d/',
  OWNERSHIPS: '/ownerships/',
  OWNERSHIPS_DETAIL: '/ownerships/%d/',
  BANKS: '/bank/',
  BANKS_DETAIL: '/bank/%d/',
  NOTIFICATIONS: '/history/',
  NOTIFICATIONS_DONE: '/history/%d/done/',
  EXPORT: '/export/',
  EXPORT_CONFIG: '/export/config/',
  USERS_GROUPS: '/groups/',
  USERS_GROUPS_DETAIL: '/groups/%d/',
  USERS: '/users/',
  USERS_DETAIL: '/users/%d/',
  USERS_PASSWORD: '/users/%d/change-password/',
  BILLING_CONTRIBUTIONS: '/fee/',
  BILLING_CONTRIBUTIONS_DETAIL: '/fee/%d/',
  BILLING_COMPANY_CATEGORIES: '/business_type/',
  BILLING_COMPANY_CATEGORIES_DETAIL: '/business_type/%d/',
  AGREEMENT: '/agreement/',
  BUSINESS_ACTIVITIES: '/business-activities/',
  BUSINESS_ACTIVITIES_DETAIL: '/business-activities/%d/',
  ECONOMIC_ACTIVITIES: '/economic-activities/',
  ECONOMIC_ACTIVITIES_DETAIL: '/economic-activities/%d/',
  STATISTICS_COMPANIES_BY_STATUS: '/statistic-by-status/',
  STATISTICS_FINANCES_INCOME: '/statistic/income/',
  STATISTICS_NEW_COMPANIES_BY_YEARS: '/statistic-by-years/',
  STATISTICS_COMPANIES_BY_BUSINESS_TYPES: '/statistic-category/',
} as const;
type IRequestAPI = typeof RequestAPI[keyof typeof RequestAPI];

const RequestHeader = {
  ACCEPT: 'Accept',
  AUTH: 'Authorization',
} as const;

const RequestStatus = {
  NOT_AUTH: 401,
  NOT_FOUND: 404,
  SERVER: 500,
} as const;

const RequestTransformType = {
  CAMEL_CASE: 'CAMEL_CASE',
  NONE: 'NONE',
} as const;
type IRequestTransformType = typeof RequestTransformType[keyof typeof RequestTransformType];

const RequestContentType = {
  JSON: 'application/json',
} as const;
type IRequestContentType = typeof RequestContentType[keyof typeof RequestContentType];

const baseUrl = `${process.env.REACT_APP_API_URL || RequestAPI.API_URL}${RequestAPI.API_VERSION}`;

export type {
  IRequestAPI,
  IRequestTransformType,
  IRequestContentType,
};
export {
  baseUrl,
  RequestAPI,
  RequestHeader,
  RequestStatus,
  RequestContentType,
  RequestTransformType,
};
