import { createApi } from '@reduxjs/toolkit/dist/query/react';
import StateName from 'helpers/stateNames';
import { RequestAPI } from 'helpers/requests';
import { axiosBaseQuery } from 'helpers/axios';
import { dataToSnakeCase } from 'helpers/register';
import { vsprintf } from 'sprintf-js';
import {
  IContractsListArgs,
  IContractsListByCompanyArgs,
  IContractsDetailArgs,
  IContractsInvoiceListArgs,
  IContractsAddArgs,
  IContractsEditArgs,
  IContractsRemoveArgs,
  IContractsRestoreArgs,
  IContractsActivateArgs,
  IContractsPayArgs,
  IContractsRegisterArgs,
  IContractsSearchArgs,
  IContractsInvoicesByCompanyArgs,
  IContractsInvoicesAddArgs,
  IContractsInvoicesActivateArgs,
  IContractsInvoicesPayReturnArgs,
  IContractsInvoicesPayArgs,
  IContractsInvoicesEditArgs,
  IContractsInvoicesDetailArgs,
  IContractsInvoicesSendArgs,
  IContractsInvoicesRemoveArgs,
  IContractsInvoicesRestoreArgs,
  IContractsAddCommentArgs,
} from 'components/services/contracts/contractsTypes';
import {
  IContractsListResponse,
  IContractsDetailResponse,
  IContractsInvoiceListResponse,
  IContractsInvoicesDetailResponse,
  IContractsInvoiceListByCompanyResponse,
  IContractsAddCommentResponse,
} from 'types/response';
import { companiesApi } from 'components/services/companies/companiesService';

const contractsApi = createApi({
  reducerPath: StateName.CONTRACTS_API,
  baseQuery: axiosBaseQuery(),
  tagTypes: [ 'ContractsList', 'ContractsDetail', 'ContractInvoicesList', 'ContractInvoicesDetail' ],
  endpoints: (build) => ({
    list: build.query<IContractsListResponse, IContractsListArgs>({
      query(args) {
        return {
          url: RequestAPI.CONTRACTS,
          params: dataToSnakeCase(args),
        };
      },
      providesTags: [ 'ContractsList' ],
    }),
    listByCompany: build.query<IContractsListResponse, IContractsListByCompanyArgs>({
      query({ id, params }) {
        return {
          url: RequestAPI.CONTRACTS,
          params: { company: id, ...dataToSnakeCase(params) },
        };
      },
      providesTags: [ 'ContractsList' ],
    }),
    detail: build.query<IContractsDetailResponse, IContractsDetailArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.CONTRACTS_DETAIL, [ id ]),
        };
      },
      providesTags: [ 'ContractsDetail' ],
    }),
    add: build.mutation<IContractsDetailResponse, IContractsAddArgs>({
      query(args) {
        return {
          url: RequestAPI.CONTRACTS,
          method: 'POST',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'ContractsList', 'ContractsDetail', 'ContractInvoicesList', 'ContractInvoicesDetail' ],
    }),
    edit: build.mutation<IContractsDetailResponse, IContractsEditArgs>({
      query({ id, ...params }) {
        return {
          url: vsprintf(RequestAPI.CONTRACTS_DETAIL, [ id ]),
          method: 'PUT',
          data: dataToSnakeCase(params),
        };
      },
      invalidatesTags: [ 'ContractsList', 'ContractsDetail', 'ContractInvoicesList', 'ContractInvoicesDetail' ],
    }),
    remove: build.mutation<void, IContractsRemoveArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.CONTRACTS_DETAIL, [ id ]),
          method: 'DELETE',
        };
      },
      invalidatesTags: [ 'ContractsList', 'ContractsDetail', 'ContractInvoicesList', 'ContractInvoicesDetail' ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(companiesApi.util.invalidateTags([ 'CompaniesList', 'CompaniesDetail' ]));
      },
    }),
    restore: build.mutation<void, IContractsRestoreArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.CONTRACTS_RESTORE, [ id ]),
          method: 'POST',
        };
      },
      invalidatesTags: [ 'ContractsList', 'ContractsDetail', 'ContractInvoicesList', 'ContractInvoicesDetail' ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(companiesApi.util.invalidateTags([ 'CompaniesList', 'CompaniesDetail' ]));
      },
    }),
    activate: build.mutation<IContractsDetailResponse, IContractsActivateArgs>({
      query({ id, ...params }) {
        return {
          url: vsprintf(RequestAPI.CONTRACTS_ACTIVATE, [ id ]),
          method: 'PUT',
          data: dataToSnakeCase(params),
        };
      },
      invalidatesTags: [ 'ContractsList', 'ContractsDetail', 'ContractInvoicesList', 'ContractInvoicesDetail' ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(companiesApi.util.invalidateTags([ 'CompaniesList', 'CompaniesDetail' ]));
      },
    }),
    pay: build.mutation<IContractsDetailResponse, IContractsPayArgs>({
      query({ id, ...params }) {
        return {
          url: vsprintf(RequestAPI.CONTRACTS_PAY, [ id ]),
          method: 'PUT',
          data: dataToSnakeCase(params),
        };
      },
      invalidatesTags: [ 'ContractsList', 'ContractsDetail', 'ContractInvoicesList', 'ContractInvoicesDetail' ],
    }),
    register: build.mutation<IContractsDetailResponse, IContractsRegisterArgs>({
      query(args) {
        return {
          url: RequestAPI.CONTRACTS,
          method: 'POST',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'ContractsList', 'ContractsDetail', 'ContractInvoicesList', 'ContractInvoicesDetail' ],
    }),
    search: build.query<IContractsListResponse, IContractsSearchArgs>({
      query(args) {
        return {
          url: RequestAPI.CONTRACTS,
          params: dataToSnakeCase({ ...args, pageSize: 5 }),
        };
      },
      providesTags: [ 'ContractsList' ],
    }),
    addComment: build.mutation<IContractsAddCommentResponse, IContractsAddCommentArgs>({
      query({ contractId, ...args }) {
        return {
          url: vsprintf(RequestAPI.CONTRACTS_COMMENT, [ contractId ]),
          method: 'PUT',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'ContractsList', 'ContractsDetail' ],
    }),
    invoicesList: build.query<IContractsInvoiceListResponse, IContractsInvoiceListArgs>({
      query(args) {
        return {
          url: RequestAPI.CONTRACT_INVOICES,
          params: dataToSnakeCase(args),
        };
      },
      providesTags: [ 'ContractInvoicesList' ],
    }),
    invoicesDetail: build.query<IContractsInvoicesDetailResponse, IContractsInvoicesDetailArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.CONTRACT_INVOICE_DETAIL, [ id ]),
        };
      },
      providesTags: [ 'ContractInvoicesDetail' ],
    }),
    invoicesListByCompany: build.query<IContractsInvoiceListByCompanyResponse, IContractsInvoicesByCompanyArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.CONTRACT_INVOICES_BY_COMPANY, [ id ]),
        };
      },
      providesTags: [ 'ContractInvoicesList' ],
    }),
    invoicesAdd: build.mutation<IContractsInvoicesDetailResponse, IContractsInvoicesAddArgs>({
      query(args) {
        return {
          url: RequestAPI.CONTRACT_INVOICES,
          method: 'POST',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'ContractsList', 'ContractsDetail', 'ContractInvoicesList', 'ContractInvoicesDetail' ],
    }),
    invoicesEdit: build.mutation<IContractsInvoicesDetailResponse, IContractsInvoicesEditArgs>({
      query({ id, ...params }) {
        return {
          url: vsprintf(RequestAPI.CONTRACT_INVOICES_EDIT, [ id ]),
          method: 'PUT',
          data: dataToSnakeCase(params),
        };
      },
      invalidatesTags: [ 'ContractsList', 'ContractsDetail', 'ContractInvoicesList', 'ContractInvoicesDetail' ],
    }),
    invoicesActivate: build.mutation<void, IContractsInvoicesActivateArgs>({
      query({ id, ...params }) {
        return {
          url: vsprintf(RequestAPI.CONTRACT_INVOICES_ACTIVATE, [ id ]),
          method: 'PUT',
          data: dataToSnakeCase(params),
        };
      },
      invalidatesTags: [ 'ContractsList', 'ContractsDetail', 'ContractInvoicesList', 'ContractInvoicesDetail' ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(companiesApi.util.invalidateTags([ 'CompaniesList', 'CompaniesDetail' ]));
      },
    }),
    invoicesPay: build.mutation<void, IContractsInvoicesPayArgs>({
      query({ id, ...params }) {
        return {
          url: vsprintf(RequestAPI.CONTRACT_INVOICES_PAY, [ id ]),
          method: 'PUT',
          data: dataToSnakeCase(params),
        };
      },
      invalidatesTags: [ 'ContractsList', 'ContractsDetail', 'ContractInvoicesList', 'ContractInvoicesDetail' ],
    }),
    invoicesPayReturn: build.mutation<void, IContractsInvoicesPayReturnArgs>({
      query({ id, ...params }) {
        return {
          url: vsprintf(RequestAPI.CONTRACT_INVOICES_PAY_RETURN, [ id ]),
          method: 'PUT',
          data: dataToSnakeCase(params),
        };
      },
      invalidatesTags: [ 'ContractsList', 'ContractsDetail', 'ContractInvoicesList', 'ContractInvoicesDetail' ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(companiesApi.util.invalidateTags([ 'CompaniesList', 'CompaniesDetail' ]));
      },
    }),
    invoicesSend: build.mutation<void, IContractsInvoicesSendArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.CONTRACT_INVOICES_SEND, [ id ]),
          method: 'PUT',
        };
      },
      invalidatesTags: [ 'ContractsList', 'ContractsDetail', 'ContractInvoicesList', 'ContractInvoicesDetail' ],
    }),
    invoicesRemove: build.mutation<void, IContractsInvoicesRemoveArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.CONTRACT_INVOICE_DETAIL, [ id ]),
          method: 'DELETE',
        };
      },
      invalidatesTags: [ 'ContractsList', 'ContractsDetail', 'ContractInvoicesList', 'ContractInvoicesDetail' ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(companiesApi.util.invalidateTags([ 'CompaniesList', 'CompaniesDetail' ]));
      },
    }),
    invoicesRestore: build.mutation<void, IContractsInvoicesRestoreArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.CONTRACT_INVOICE_RESTORE, [ id ]),
          method: 'POST',
        };
      },
      invalidatesTags: [ 'ContractsList', 'ContractsDetail', 'ContractInvoicesList', 'ContractInvoicesDetail' ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(companiesApi.util.invalidateTags([ 'CompaniesList', 'CompaniesDetail' ]));
      },
    }),
  }),
});

export {
  contractsApi,
};
